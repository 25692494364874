body {
    background-color: rgb(67, 50, 50);
  } 
 
  .app {
    padding: 9rem;
    width:max-content;
    margin: 0 auto;
    margin-top: 100px;
    margin-bottom: 100px;
  }

  .btn {
    font-weight: 1000;
    font-size: 30px; 
    display:flex;
    justify-content:center;
    float: right;
    background-color: rgb(67, 50, 50);
    cursor: pointer;
    padding: 20px;
    margin-top: 0.5rem;
    margin-right: 2rem;
    width: 200px;
    color: white;
    border-radius: 15%;
    border: 5px solid;
  }

  footer {
    font-size: 20px;
    font-style: italic;
    font-weight: 700;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 50px;
    color: white;
  }