

.answer {
    width: 40%;
    background: #fff;
    border: 2px solid #755a5a;
    min-height: 70px;
    cursor: pointer;
    margin-bottom: 30px;
    display: flex;
    border-radius: 10%;
  
  }
  
 
  .answer-letter {
    font-size: 40px;
    font-weight: 800;
    background-color: #b3a5a5;
    color: rgb(13, 12, 12);
    width: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20%;
  }
  
  .answer-text {
    font-weight: 600;
    font-size: 30px;
    display: flex;
    align-items: center;
    margin-left: 20px;
  }
  
  .correct-answer {
    border: 4px solid #08ec6e;
  }
  
  .correct-answer .answer-letter {
    background: #08ec6e;
  }
  
  .wrong-answer {
    border: 4px solid #e72a2a;
  }
  
  .wrong-answer .answer-letter {
    background: #e72a2a;
  }
  
  
  
  .score {
    background: #fff;
    color: 000;
    font-size: 30px;
    width: 200px;
    margin: 0 auto;
    font-weight: 600;
    height: 40px;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10%;}
  
  .disabled-answer {
    pointer-events: none;
  }