.h2 {
    color: white;
    padding: 20px;
    min-height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 80px;
    font-weight: 700;
    border-radius: 10%;
  }
  .answers {
    display: flex;
    flex-wrap: wrap;
    justify-content:space-between;
    margin-top: 50px;
  }
  

  .results {
    text-align: center;
    width: 100%;
    padding: 4rem;
    margin-top: 100px;
    margin-bottom: 100px;
  }
  
   
  .results-info {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    margin-bottom: 40px;
    font-size: 70px;
    font-weight: 900;
    font-style: italic;
  }

  .well-done {
    font-size: 90px;
    font-weight: 900;
    color:white;
    height: 10%;
    /* margin-top: 200px; */
   
  }
  .play-again {
    color: white;
    font-size: 25px;
    font-weight: 900;
    /* padding: 2%; */
    cursor: pointer;
    border: 5px solid;
    border-radius: 10%;
    width: 90px;
    margin: 0 auto;
    margin-top: 200px;
  }

  .next-button {
    margin: 0 auto;
    color: rgb(7, 6, 6);
    background: white;
    width: 100px;
    font-size: 18px;
    font-weight: 900;
    padding: 14px 75px;
    text-transform: uppercase;
    cursor: pointer;
    text-align: center;
    border-radius: 10%;
    margin-top: 200px;
  }