 h1 {
    color: white;
    font-size: 150px;
    text-align: center;
    display: flex;
    justify-content: center;
    padding-top: 9rem;
  }

  h2 {
    color: white;
    font-size: 70px;
    display: flex;
    justify-content: center;
    padding-bottom: 3rem;
  }

  a {
    font-size: 40px;
    display: flex;
    justify-content: center;
    color: white;
  }
  
  p {
    font-size: 25px;
    display: flex;
    justify-content: center;
    font-weight: 600;
    color: white;
  }
  .nav {
    display: flex;
    justify-content: center;
    justify-content: space-between;
    margin-top: 80px;
    padding: 2rem;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    border: 10px solid white;
    border-radius: 10%;
  
  }

 ul {
    display:flex;
    list-style:none;
    padding: 3px;
    justify-content: space-between;
    margin-right: 200px;
}

li {
  padding:10px 10px;
  border-radius:15%;
  border: 5px solid white;
  font-weight: 700;
  font-size: 40px;
  
}
  
  
  .name {
    font-weight: 900;
    font-family: Verdana;
    font-style:oblique;
    color:rgb(212, 72, 72);
    font-size: 100px;
    margin-left: 200px;
    text-align: center;
  
  };

 